import { mapGetters } from 'vuex'
import '@/css/home.scss'
// const navModule_1 = () => import('@/pages/modules/navModule_1.vue') //代码切割

export default {
	components: {
		// navModule_1,
	},
	computed: {
		...mapGetters({
			host: 'host',
			showDebug: 'showDebug',
			deviceType: 'deviceType',
		}),
	},
}
